import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';

import { useNavigationContext } from 'contexts/navigation-context';
import { isAuthenticated } from 'services/authentication-service';

const LazyLogoutPage = loadable(() => import('suspended-page/logout'));

const LogoutPage = () => {
  const [forceToLandingPage, setForceToLandingPage] = useState(false);

  const { landingPageOnly = () => {} } = useNavigationContext();
  const authenticated = isAuthenticated();

  useEffect(() => setForceToLandingPage(!authenticated), [authenticated]);

  if (!forceToLandingPage) {
    landingPageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  return (<LazyLogoutPage/>);
};

export default LogoutPage;
